import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import guardService from '../../assets/Service gardiennage.jpg';
import { colors } from '../../utils/constant';
import { listItemProps } from '../../utils/types';
import {
  StyledBoxSubTitleCall,
  StyledImage,
  StyledImageSection,
  StyledLink,
  StyledSection,
  StyledTextSection,
} from './firstInfoStyle';

export interface FirstInfoProps {
  title?: string;
  subtitle?: string;
  caption?: string;
  titleParagraph?: ReactNode;
  firstParagraph?: ReactNode;
  lastParagraph?: ReactNode;
  listItems?: listItemProps[];
  imgSrc?: string;
}

const FirstInfo: FC<FirstInfoProps> = ({
  title,
  titleParagraph,
  subtitle,
  firstParagraph,
  lastParagraph,
  caption,
  listItems = [],
  imgSrc = guardService,
}) => {
  const theme = useTheme();

  return (
    <Box bgcolor={colors.white} padding="24px">
      <Typography component="h2" fontWeight="bold" textAlign="center" variant="h4" mb="24px">
        {title}
      </Typography>
      <StyledSection theme={theme}>
        <StyledTextSection theme={theme} marginBottom="24px">
          <StyledBoxSubTitleCall theme={theme}>
            <Typography fontWeight="bold" variant="h6" marginBottom={0}>
              Des questions ? Réponse rapide au
            </Typography>
            <StyledLink href="tel:+33388315950" underline="hover">
              {' 03 88 31 59 50'}
            </StyledLink>
          </StyledBoxSubTitleCall>
          <Typography variant="body1" gutterBottom>
            {subtitle}
          </Typography>
          {titleParagraph}
          {firstParagraph}
          <List>
            {listItems.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  component={Link}
                  to={item.href}
                  sx={{
                    'padding': 0,
                    'textDecoration': 'none',
                    '&:hover': {
                      textDecoration: 'none',
                      color: colors.secondary,
                    },
                  }}
                >
                  <ChevronRightIcon sx={{ color: colors.secondary }} />
                  <ListItemText primary={item.primary} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {lastParagraph}
          <Divider
            sx={{
              marginLeft: '24px',
              borderWidth: '2px',
              width: '120px',
              backgroundColor: colors.secondary,
            }}
          />
          <Typography variant="body1" margin="20px" gutterBottom>
            {caption}
          </Typography>
          <Divider
            sx={{
              marginLeft: '24px',
              borderWidth: '2px',
              width: '30px',
              backgroundColor: colors.secondary,
            }}
          />
        </StyledTextSection>
        <StyledImageSection theme={theme}>
          <StyledImage src={imgSrc} alt="Service gardiennage" />
        </StyledImageSection>
      </StyledSection>
    </Box>
  );
};

export default FirstInfo;
