import { Box, Container, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../utils/constant';

const Footer: FC = () => {
  const usefulLinks = [
    { title: 'Agent de sécurité', path: '/agent-securite' },
    { title: 'Service de gardiennage', path: '/servicede-gardiennage' },
    { title: 'Sécurité incendie', path: '/securiteincendie' },
    { title: 'Sécurité événementielle', path: '/agentde-securite-evenementiel' },
    { title: 'Intervention sur alarme', path: '/interventionsur-alarme' },
    { title: 'Agent de sécurité magasin', path: '/agentde-securite-magasin-commerce' },
  ];

  const linkStyle = {
    'color': 'white',
    'textDecoration': 'none',
    'display': 'block',
    'transition': 'color 0.3s ease',
    '&:hover': {
      color: colors.secondary,
    },
  };

  return (
    <Box component="footer" sx={{ backgroundColor: colors.primary, color: 'white', paddingY: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit" gutterBottom>
              PRESTIGE SECURITE SARL
            </Typography>
            <Typography variant="subtitle1" color="inherit">
              2 rue gribeauval
            </Typography>
            <Typography variant="subtitle1" color="inherit" gutterBottom>
              67100 STRASBOURG
            </Typography>
            <Typography variant="subtitle1" color="inherit">
              03 88 31 59 50
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit" gutterBottom>
              À PROPOS
            </Typography>
            <Link to="/" style={linkStyle}>
              Accueil
            </Link>
            <br />
            <Link to="/contactez-nous" style={linkStyle}>
              Contactez-nous
            </Link>
            <br />
            <Link to="/mentions-legales" style={linkStyle}>
              Mentions légales
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit" gutterBottom>
              LIENS UTILES
            </Typography>
            {usefulLinks.map((link) => (
              <Box key={link.path} mb={0.5}>
                <Link to={link.path} color="inherit" style={linkStyle}>
                  {link.title}
                </Link>
              </Box>
            ))}
          </Grid>
        </Grid>
        <Box textAlign="center" paddingTop={{ xs: 5, sm: 10 }} paddingBottom={{ xs: 5, sm: 0 }}>
          PRESTIGE SÉCURITÉ - Agence de sécurité à Strasbourg
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
