import { Box, Drawer } from '@mui/material';
import React, { useState } from 'react';
import MobileDrawer from '../molecules/MobileDrawer';
import CustomAppBar from './CustomAppBar';

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  { title: 'Accueil', path: '/' },
  { title: 'Prestations', path: '/' },
  { title: 'Contactez-nous', path: '/contactez-nous' },
];

const navMobileItems = [
  { title: 'Accueil', path: '/' },
  { title: 'Agent de sécurité', path: '/agent-securite' },
  { title: 'Service de gardiennage', path: '/servicede-gardiennage' },
  { title: 'Sécurité incendie', path: '/securiteincendie' },
  { title: 'Sécurité événementielle', path: '/agentde-securite-evenementiel' },
  { title: 'Intervention sur alarme', path: '/interventionsur-alarme' },
  { title: 'Agent de sécurité magasin', path: '/agentde-securite-magasin-commerce' },
  { title: 'Contactez-nous', path: '/contactez-nous' },
];

const DrawerAppBar: React.FC = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box display="flex">
      <CustomAppBar
        anchorEl={anchorEl}
        handleOpenMenu={handleOpenMenu}
        handleCloseMenu={handleCloseMenu}
        handleDrawerToggle={handleDrawerToggle}
        navItems={navItems}
      />
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            'display': { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {<MobileDrawer handleDrawerToggle={handleDrawerToggle} navItems={navMobileItems} />}
        </Drawer>
      </nav>
    </Box>
  );
};

export default DrawerAppBar;
