import { Link, Typography } from '@mui/material';
import { FC } from 'react';
import agentDeSecuriteEvenementiel from '../assets/agentDeSecuriteEvenementiel.jpg';
import agentNocturne from '../assets/agentNocturne.jpg';
import alarmInstallation from '../assets/alarmInstallation.jpg';
import interventionSurAlarme from '../assets/Intervention sur alarme.jpg';
import security from '../assets/security.jpg';
import ServicePageTemplate from '../templates/ServicePageTemplate';
import { colors } from '../utils/constant';

const InterventionSurAlarme: FC = () => {
  const title = 'VOTRE AGENCE DE SÉCURITÉ À STRASBOURG';
  const bgImages = [agentNocturne, security, alarmInstallation, agentDeSecuriteEvenementiel];

  return (
    <ServicePageTemplate
      title={title}
      bgImages={bgImages}
      firstInfo={{
        imgSrc: alarmInstallation,
        title: "Service d'intervention sur alarme",
        subtitle: 'Nous intervenons lors d’un déclenchement d’alarme.',
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Levée</strong> de doute
          </Typography>
        ),
        firstParagraph: (
          <Typography variant="body1" gutterBottom>
            L’intervention sur alarme s’opère suite à un signal d’intrusion ou de départ d’incendie.
            Nos
            <Link href="#" underline="hover" color={colors.secondary}>
              {' agents de sécurité '}
            </Link>
            débarquent sur les lieux pour vérifier l’origine de l’alerte en effectuant une ronde de
            reconnaissance.
          </Typography>
        ),
        lastParagraph: (
          <>
            <Typography marginBottom="8px" marginTop="8px" variant="body1" gutterBottom>
              Nous inspectons les ouvertures et vérifions bien chaque pièce. En cas d’effraction
              avérée, nous prévenons les forces de l’ordre tout en établissant les mesures
              conservatoires.
            </Typography>
            <Typography marginBottom="8px" marginTop="8px" variant="body1" gutterBottom>
              Au cas où l’alarme est liée à un problème technique, nous pouvons procéder à la remise
              en marche des systèmes de surveillance.
            </Typography>
          </>
        ),
        caption: 'Nous établissons des mesures conservatoires',
      }}
      secondInfo={{
        imgSrc: interventionSurAlarme,
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="8px" marginBottom="8px">
            <strong>Intervention </strong> rapide
          </Typography>
        ),
        firstParagraph: `Notre équipe est disponible à tout moment pour répondre à un signal d’alerte. Dès le déclenchement de l’alarme et la réception de l’alerte, nous nous engageons à nous rendre le plus rapidement possible sur les lieux.`,
        secondParagraph: `Nous respectons les consignes définies au préalable avec les clients. Des outils performants sont à notre disposition pour réaliser correctement les investigations et les opérations. Nous pouvons intervenir à Strasbourg, Mulhouse, Le Mans et alentour.`,
        caption: 'Nous sommes disponibles à tout moment.',
      }}
    />
  );
};

export default InterventionSurAlarme;
