import { Box } from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { FaHandshakeSimple } from 'react-icons/fa6';
import { IoIosPeople } from 'react-icons/io';
import { RxLapTimer } from 'react-icons/rx';
import { Parallax } from 'react-parallax';
import agentWithHeadphones from '../assets/agentWithHeadphones.jpg';
import alarmInstallation from '../assets/alarmInstallation.jpg';
import controlStation from '../assets/controlStation.jpg';
import employeVisuelDeDos from '../assets/employeVisuelDeDos.jpeg';
import europeanParliament from '../assets/europeanParliament.jpeg';
import securityGuardBlack from '../assets/security-guard-black.jpg';
import teamIntervention from '../assets/teamIntervention.jpg';
import ScrollToTopButton from '../atoms/ScrollToTopButton';
import Footer from '../organisms/Footer';
import ContactHome from '../organisms/homePage/ContactHome';
import HeaderHome from '../organisms/homePage/HeaderHome';
import MultiSkilledAgentsHome from '../organisms/homePage/MultiSkilledAgentsHome';
import SlidingBlockHome from '../organisms/homePage/SlidingBlockHome';
import TrustSection from '../organisms/homePage/TrustReason';
import InfoBetweenServices from '../organisms/InfoBetweenServices';

const Accueil: FC = () => {
  const [bgImage, setBgImage] = useState<string>(securityGuardBlack);
  const images = [europeanParliament];

  const reasonsToTrust = [
    {
      title: 'Réactivité',
      description: 'Nous intervenons dans les plus brefs délais.',
      srcImage: controlStation,
      icon: <RxLapTimer size={28} />,
    },
    {
      title: 'Prestations de qualité',
      description: 'Comptez sur la fiabilité de nos services.',
      srcImage: teamIntervention,
      icon: <FaHandshakeSimple size={28} />,
    },
    {
      title: 'Professionnalisme',
      description: 'Nos agents de sécurité sont sérieux et rigoureux.',
      srcImage: employeVisuelDeDos,
      icon: <IoIosPeople size={28} />,
    },
  ];

  const changeBackgroundImage = () => {
    const currentIndex = images.indexOf(bgImage);
    const nextIndex = (currentIndex + 1) % images.length;
    setBgImage(images[nextIndex]);
  };

  useEffect(() => {
    const intervalId = setInterval(changeBackgroundImage, 10000);
    return () => clearInterval(intervalId);
  }, [bgImage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    changeBackgroundImage();
  }, []);

  return (
    <Fragment>
      <ScrollToTopButton />
      <Parallax strength={1000} bgImage={bgImage}>
        <Box height="95vh">
          <HeaderHome />
          <SlidingBlockHome />
        </Box>
      </Parallax>
      <InfoBetweenServices
        title="Entreprise de sécurité à Strasbourg"
        description="Nous sommes une entreprise de sécurité à Strasbourg qui propose des services de sécurité et
        de protection personnalisés pour les particuliers et les entreprises."
      />
      <InfoBetweenServices
        height="60vh"
        title="Une société de gardiennage"
        description="Nous intervenons lors d’un déclenchement d’alarme pour effectuer une levée de doute.
            Nous pouvons également effectuer le travail de night auditor ou réceptionniste de nuit."
        backgroundImage={agentWithHeadphones}
      />
      <TrustSection reasons={reasonsToTrust} />
      <InfoBetweenServices
        height="70vh"
        title="Nous restons à"
        description="votre disposition pour toute demande de renseignements"
        buttonLabel="Contactez-nous"
        backgroundImage={alarmInstallation}
      />
      <MultiSkilledAgentsHome />
      <ContactHome />
      <Footer />
    </Fragment>
  );
};

export default Accueil;
