import { Box } from '@mui/material';
import { FC } from 'react';

const MapWrapper: FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '400px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      }}
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21126.488396780598!2d7.766197000000001!3d48.556013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c96d6d4b4d7d%3A0xa02e1b10816e0d90!2s2%20Rue%20de%20Gribeauval%2C%2067100%20Strasbourg!5e0!3m2!1sfr!2sfr!4v1731877653018!5m2!1sfr!2sfr"
        width="100%"
        height="100%"
        style={{
          border: 0,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Localisation Prestige Sécurité"
      />
    </Box>
  );
};

export default MapWrapper;
