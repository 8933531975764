import { Global } from '@emotion/react';
import { css, useTheme } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import securityAgent from '../../assets/agentDeSecurite.jpg';
import eventSecurityAgent from '../../assets/agentDeSecuriteEvenementiel.jpg';
import agentPosition from '../../assets/agentPosition.jpg';
import teamsRCS from '../../assets/groupe-rcs.png';
import nightIntervention from '../../assets/Intervention nocturne.jpg';
import Image from '../../atoms/Image';
import { slideInFromTop } from '../../utils/keyframes';
import { StyledBox, StyledTypography } from './homePageStyle/slidingBlockHomeStyle';

const slides = [
  { src: securityAgent, text: 'Service de gardiennage', link: '/servicede-gardiennage' },
  { src: nightIntervention, text: 'Sécurité incendie', link: '/securiteincendie' },
  {
    src: teamsRCS,
    text: 'Agent de sécurité évènementiel',
    link: '/agentde-securite-evenementiel',
  },
  { src: agentPosition, text: 'Intervention sur alarme', link: '/interventionsur-alarme' },
  {
    src: eventSecurityAgent,
    text: 'Agent de sécurité magasin et commerce',
    link: '/agentde-securite-magasin-commerce',
  },
];

const SlidingBlockHome: FC = () => {
  const theme = useTheme();

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    autoplay: true,
    centerPadding: '60px',
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '100px',
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Global
        styles={css`
          .slick-slide {
            visibility: hidden;
            opacity: 0;
            animation: ${slideInFromTop} 1s ease-out;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
            margin-top: 24px;
            margin-bottom: 24px;
          }
          .slick-slide div {
            margin: 0 8px;
            border-radius: 15px;
          }
          .slick-slide img {
            border-radius: 18px;
          }
        `}
      />
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <StyledBox key={index} theme={theme}>
            <Link to={slide.link}>
              <Image href={slide.link} width="100%" src={slide.src} alt={slide.text} />
              <StyledTypography>{slide.text}</StyledTypography>
            </Link>
          </StyledBox>
        ))}
      </Slider>
    </>
  );
};

export default SlidingBlockHome;
