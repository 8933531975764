import { Typography } from '@mui/material';
import { FC } from 'react';
import europeanParliament from '../assets/europeanParliament.jpeg';
import eventSig from '../assets/event-sig.jpeg';
import eventSig2 from '../assets/event-sig2.jpeg';
import ServicePageTemplate from '../templates/ServicePageTemplate';
import { listItemProps } from '../utils/types';

const AgentDeSecuriteEvenementiel: FC = () => {
  const title = 'VOTRE AGENCE DE SÉCURITÉ À STRASBOURG';
  const bgImages = [europeanParliament];

  const listItems: listItemProps[] = [
    { href: '/servicede-gardiennage', primary: 'Service de gardiennage des locaux,' },
    { href: '/agent-securite', primary: 'Agents de sécurité qualifiés,' },
    { href: '/interventionsur-alarme', primary: 'Intervention et surveillance périmétrique,' },
    { href: '/securiteincendie', primary: 'Sécurité incendie et évacuation,' },
    { href: '/agentde-securite-magasin-commerce', primary: 'Contrôle des accès et surveillance,' },
  ];

  return (
    <ServicePageTemplate
      title={title}
      bgImages={bgImages}
      firstInfo={{
        imgSrc: eventSig,
        title: 'Entreprise de sécurité événementielle à Strasbourg',
        subtitle:
          'Nos agents sont à votre service pour assurer la sécurité durant les événements que vous organisez.',
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Protection des lieux</strong> <br /> et des personnes
          </Typography>
        ),
        firstParagraph: (
          <Typography variant="body1" gutterBottom>
            Nous proposons des solutions de sécurité adaptées à tous types d’événements : les
            mariages, les foires, les expositions, les concerts, les soirées privés, les événements
            sportifs, etc.
          </Typography>
        ),
        listItems,
        lastParagraph: (
          <Typography marginBottom="24px" marginTop="24px" variant="body1" gutterBottom>
            Nous pouvons aussi assurer la protection rapprochée de VIP.
          </Typography>
        ),
        caption:
          'Nous assurons la sécurisation de vos événements familiaux, professionnels, culturels ou sportifs.',
      }}
      secondInfo={{
        imgSrc: eventSig2,
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Des événements</strong> <br />
            bien sécurisés
          </Typography>
        ),
        firstParagraph: `Préserver la sécurité du public et des biens mis en place est primordiale lors d’une festivité. Faites appel à notre entreprise pour prévenir et gérer les éventuels risques et incidents (mouvement de foule, incendie, accident...) qui peuvent se présenter.`,
        secondParagraph: `Nous mettons en place des services de sécurité appropriés suivant les lieux, la superficie et le nombre de personnes afin de garantir le bon déroulement des manifestations.`,
        thirdParagraph:
          'Nos agents spécialisés peuvent se mobiliser dans les environs de Strasbourg, Mulhouse et Le Mans.',
        caption: 'Nous mettons en place des services de sécurité appropriés à vos manifestations.',
      }}
    />
  );
};

export default AgentDeSecuriteEvenementiel;
