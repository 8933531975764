import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import prestigeSecurite from '../assets/logo.png';
import { colors } from '../utils/constant';

interface AppBarProps {
  anchorEl: HTMLElement | null;
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseMenu: () => void;
  handleDrawerToggle: () => void;
  navItems: Array<{ title: string; path: string }>;
}

const CustomAppBar: FC<AppBarProps> = ({
  anchorEl,
  handleOpenMenu,
  handleCloseMenu,
  handleDrawerToggle,
  navItems,
}: AppBarProps) => {
  const [opacity, setOpacity] = useState(0);
  // TODO Modal pour afficher le champ de la demande d'appel
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const handleOpenModal = () => setIsModalOpen(true);
  // const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      {/* TODO Modal pour afficher le champ de la demande d'appel */}
      {/* <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal} title="Titre du Modal" />*/}
      <AppBar component="nav" sx={{ paddingY: 0, display: 'flex' }}>
        <Toolbar sx={{ opacity, transition: 'opacity 1s' }}>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ color: colors.white }} />
          </IconButton>
          <Typography
            to="/"
            variant="body1"
            component={Link}
            flexGrow={1}
            fontFamily="Roboto"
            fontWeight="bold"
            color={colors.white}
            sx={{ textDecoration: 'none' }}
            display={{ xs: 'none', sm: 'block' }}
          >
            PRESTIGE SÉCURITÉ
          </Typography>
          <Box flexGrow={1} display="block" />
          <Box
            component="img"
            height={40}
            marginRight={2}
            display="block"
            alt="Logo Prestige Sécurité"
            src={prestigeSecurite}
          />
          <Box flexGrow={1} display="block" />
          <Box display={{ xs: 'none', sm: 'flex' }}>
            {navItems.map((item) => {
              if (item.title === 'Prestations') {
                return (
                  <div key={item.title}>
                    <Button
                      size="small"
                      onMouseOver={handleOpenMenu}
                      sx={{
                        'color': colors.white,
                        'fontFamily': 'Roboto',
                        'fontWeight': 'bold',
                        '&:hover': {
                          backgroundColor: colors.secondary,
                          transition: 'background-color 0.3s ease-in-out',
                        },
                      }}
                    >
                      {item.title}
                    </Button>
                    <Menu
                      sx={{ borderRadius: 15 }}
                      id="prestations-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        sx: { backgroundColor: colors.primary, color: colors.white },
                        onMouseLeave: handleCloseMenu,
                      }}
                    >
                      <MenuItem
                        sx={{
                          'color': colors.white,
                          '&:hover': {
                            color: colors.secondary,
                            transition: 'color 0.4s ease-in-out',
                          },
                        }}
                        component={Link}
                        to="/agent-securite"
                      >
                        Agent de sécurité
                      </MenuItem>
                      <MenuItem
                        sx={{
                          'color': colors.white,
                          '&:hover': {
                            color: colors.secondary,
                            transition: 'color 0.4s ease-in-out',
                          },
                        }}
                        component={Link}
                        to="/servicede-gardiennage"
                      >
                        Service de gardiennage
                      </MenuItem>
                      <MenuItem
                        sx={{
                          'color': colors.white,
                          '&:hover': {
                            color: colors.secondary,
                            transition: 'color 0.4s ease-in-out',
                          },
                        }}
                        component={Link}
                        to="/securiteincendie"
                      >
                        Sécurité incendie
                      </MenuItem>
                      <MenuItem
                        sx={{
                          'color': colors.white,
                          '&:hover': {
                            color: colors.secondary,
                            transition: 'color 0.4s ease-in-out',
                          },
                        }}
                        component={Link}
                        to="/agentde-securite-evenementiel"
                      >
                        Sécurité événementielle
                      </MenuItem>
                      <MenuItem
                        sx={{
                          'color': colors.white,
                          '&:hover': {
                            color: colors.secondary,
                            transition: 'color 0.4s ease-in-out',
                          },
                        }}
                        component={Link}
                        to="/interventionsur-alarme"
                      >
                        Intervention sur alarme
                      </MenuItem>
                      <MenuItem
                        sx={{
                          'color': colors.white,
                          '&:hover': {
                            color: colors.secondary,
                            transition: 'color 0.4s ease-in-out',
                          },
                        }}
                        component={Link}
                        to="/agentde-securite-magasin-commerce"
                      >
                        Agent de sécurité magasin
                      </MenuItem>
                    </Menu>
                  </div>
                );
              }
              return (
                <Button
                  size="small"
                  key={item.title}
                  component={Link}
                  to={item.path}
                  sx={{
                    'color': colors.white,
                    'fontFamily': 'Roboto',
                    'fontWeight': 'bold',
                    'paddingBottom': '5px',
                    'borderBottom': '3px solid transparent',
                    '&:hover': {
                      color: colors.secondary,
                      transform: 'scale(1.2)',
                      transition: 'color transform 0.4s ease-in-out',
                    },
                  }}
                >
                  {item.title}
                </Button>
              );
            })}
          </Box>
          {/* TODO ajout d'un bouton pour demander un appel prochainement */}
          {/* <Box flexGrow={1} display="block" />*/}
          {/* <Box*/}
          {/*  m={0}*/}
          {/*  p={0}*/}
          {/*  height="100%"*/}
          {/*  bgcolor={colors.secondary}*/}
          {/*  display={{ xs: 'none', sm: 'flex' }}*/}
          {/*  alignItems="center"*/}
          {/*  justifyContent="center"*/}
          {/*  borderRadius="25px"*/}
          {/* >*/}
          {/*  <IconButton color="inherit" aria-label="open modal" onClick={handleOpenModal}>*/}
          {/*    <CallIcon />*/}
          {/*  </IconButton>*/}
          {/* </Box>*/}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default CustomAppBar;
